var stickyHeader = true;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 1500;


// 617284 Remove Animated 'Snowflakes' From the Website
// // Snowfall
// $(document).ready(function () {
//   var $snowfall = $('<div class="snowfall" />');
//   $('body.homepage:not(.subsite)').append($snowfall);

//     for (var i = 0; i < 120; i++) {
//         var $snowflake = $('<div/>', {
//             "class": "snowflake"
//         });
//         $snowfall.append($snowflake);
//     }
// });


// Homepage Snippets
$(".homeFeatureSnippets:not(.homefeaturecategory-homeboximpactstats):not(.homefeaturecategory-homeboxquickgiving)").insertAfter(".homeIntroWrapper");


// Spacing - If no Homeboxes
$(document).ready(function () {
    if ( $('.homeFeatures').children().length == 0 ) {
        $('.homeFeatures').css("padding", "0");
    }
});